import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomeOne from "./Components/Home/HomeOne";
import ErrorPage from "./Components/Pages/404Page";
import TermsOfUse from "./Components/Pages/TermsOfUse";

function App() {
  return (
    <BrowserRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/tnc" element={<TermsOfUse />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
