import React from "react";
import ContentList from "./ContentList";

const UsefulContent = () => {
  return (
    <div id="useful-content">
      {/* <Header /> */}
      <div className="text-center">
        <div className="column-title ">
          Useful <span className="shape-bg">Content</span>
        </div>
      </div>
      <ContentList />
      {/* <Footer /> */}
    </div>
  );
};

export default UsefulContent;
