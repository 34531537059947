/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import checkIcon from '../assets/images/icon/ck.svg';
import phoneIcon from '../assets/images/icon/headphone.svg';
import mailIcon from '../assets/images/icon/mail.svg';
import userIcon from '../assets/images/icon/user.svg';
import { postStaffAppreciationLunch } from '../constant/services/home';
import { LoadCanvasTemplate3, loadCaptchaEnginge3, validateCaptcha3 } from '../utils/react-simple-captcha';

const DUTIES_LIST = [
	{
		key: 1,
		name: 'Setting up tables and chairs',
	},
	{
		key: 2,
		name: 'Serving food',
	},
	{
		key: 3,
		name: 'Contribute dishes',
	},
	{
		key: 4,
		name: 'Clean up',
	},
	{
		key: 5,
		name: 'Financial donation at https://schoolcashonline.com',
	},
];

const Checkbox = ({ obj, onChange }) => {
	return (
		<label>
			<input
				type="checkbox"
				id={`custom-checkbox-${obj?.key}`}
				name={obj?.name}
				value={obj?.checked}
				onChange={() => onChange({ ...obj, checked: !obj?.checked })}
			/>
			&nbsp;{obj?.name}
		</label>
	);
};

const StaffAppreciationLuncheon = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phonenumber, setPhoneNumber] = useState('');
	const [duties, setDuties] = useState([...DUTIES_LIST]);
	const [dishName, setDishName] = useState('');
	const [dishServing, setDishServing] = useState('');
	const [dishIngredients, setDishIngredients] = useState('');
	const [captcha, setCaptcha] = useState('');

	const onSubmit = async () => {
		if (!name) {
			alert('Please enter name.');
			return;
		}
		if (!email) {
			alert('Please enter email.');
			return;
		}
		const selectedDuties = duties.filter(d => d.checked).map(d => d.name);
		if (!selectedDuties?.length) {
			alert('Please select atleast one duty.');
			return;
		}
		if (dishName && !dishIngredients) {
			alert('Please enter dish ingredients.');
			return;
		}
		if (dishName && !dishServing) {
			alert('Please enter dish serving.');
			return;
		}
		if (!captcha) {
			alert('Please enter captcha.');
			return;
		}
		if (!validateCaptcha3(captcha)) {
			alert('Captcha validation failed.');
			return;
		}
		await postStaffAppreciationLunch({
			data: {
				name,
				email,
				phonenumber,
				duties: selectedDuties.join(', '),
				dish: dishName,
				ingredients: dishIngredients,
				servings: dishServing
			},
		});
		alert('Thank You for registering for Staff Appreciation Lunch. We will get back to you soon.');
		setEmail('');
		setName('');
		setPhoneNumber('');
		setDishName('');
		setDishIngredients('');
		setDishServing('');
		setDuties([...DUTIES_LIST]);
		setCaptcha('');
		loadCaptchaEnginge3(6);
	};

	useEffect(() => {
		loadCaptchaEnginge3(6);
	}, []);

	return (
		<div id="staff-appreciation-luncheon" style={{ paddingTop: 0 }}>
			<div
				className="container"
				style={{ backgroundColor: '#E2EDE8', display: 'flex', justifyContent: 'center' }}
			>
				<div className="single-footer" style={{ maxWidth: 450 }}>
					<div className="flex">
						<div className="mb-4 mt-4 flex items-center rounded-md bg-white pl-6">
							<div className="flex-none">
								<span className=" ">
									<img src={userIcon} alt="" />
								</span>
							</div>
							<div className="flex-1">
								<input
									type="text"
									placeholder="Enter Name"
									className="border-none focus:ring-0"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="mb-4 mt-4 flex items-center rounded-md bg-white pl-6 shadow-e1 ml-4">
							<div className="flex-none">
								<span className=" ">
									<img src={mailIcon} alt="" />
								</span>
							</div>
							<div className="flex-1">
								<input
									type="text"
									placeholder="Enter E-mail"
									className="border-none focus:ring-0"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="mb-4 flex items-center rounded-md bg-white pr-[10px] pl-6 shadow-e1">
						<div className="flex-none">
							<span className=" ">
								<img src={phoneIcon} alt="" />
							</span>
						</div>
						<div className="flex-1">
							<input
								type="text"
								placeholder="Enter Phonenumber"
								className="border-none focus:ring-0"
								value={phonenumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
						</div>
					</div>
					<div className="mb-4 flex rounded-md pr-[10px] pl-6 shadow-e1 flex-col items-start">
						<label>
							<b>Duties:</b>
						</label>
						{DUTIES_LIST.map((obj) => (
							<Checkbox
								key={obj.key}
								obj={obj}
								onChange={(item) => {
									setDuties(duties.map((d) => (d.key === item.key ? item : d)));
								}}
							/>
						))}
					</div>
					<div className="mb-4 flex items-center rounded-md pr-[10px] pl-6 shadow-e1">
						If contributing a dish, please indicate here a short list of ingredients. And also the probable servings.
					</div>
					<div className="flex">
						<div className="mb-4 flex items-center rounded-md bg-white pl-6">
							<div className="flex-1">
								<input
									type="text"
									placeholder="Enter Dish Name"
									className="border-none focus:ring-0"
									value={dishName}
									onChange={(e) => setDishName(e.target.value)}
								/>
							</div>
						</div>
						<div className="mb-4 flex items-center rounded-md bg-white pl-6 shadow-e1 ml-4">
							<div className="flex-1">
								<input
									type="number"
									placeholder="Enter No. of Servings"
									className="border-none focus:ring-0"
									value={dishServing}
									onChange={(e) => setDishServing(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="mb-4 flex items-center rounded-md bg-white pr-[10px] pl-6 shadow-e1">
						<div className="flex-1">
							<input
								type="text"
								placeholder="Enter Ingredients"
								className="border-none focus:ring-0"
								value={dishIngredients}
								onChange={(e) => setDishIngredients(e.target.value)}
							/>
						</div>
					</div>
					<div className="flex">
						<div className="mb-4 flex items-center rounded-md bg-white shadow-e1 captcha-container2">
							<LoadCanvasTemplate3 />
						</div>
						<div className="mb-4 flex items-center rounded-md bg-white pl-6 shadow-e1 ml-4">
							<div className="flex-none">
								<span className=" ">
									<img src={checkIcon} alt="mainIcon" />
								</span>
							</div>
							<div className="flex-1">
								<input
									type="text"
									placeholder="Enter Captcha"
									className="border-none focus:ring-0"
									value={captcha}
									onChange={(e) => setCaptcha(e.target.value)}
								/>
							</div>
						</div>
					</div>

					<button className="btn btn-primary block w-full text-center" onClick={onSubmit}>
						Submit
					</button>
					<div style={{ fontSize: 12, textTransform: 'none', textAlign: 'center' }}>
						By using this site you agree to{' '}
						<Link to="/tnc" style={{ color: '-webkit-link', textDecoration: 'underline' }}>
							T&C.
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StaffAppreciationLuncheon;
