const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getThumbnailURL = (item, isSingleMode = false) => {
    const format = isSingleMode ? item?.attributes?.photo?.data?.attributes?.formats : item?.attributes?.photo?.data?.[0]?.attributes?.formats;
    let imageUrl = format?.medium?.url;
    if (!imageUrl) {
        imageUrl = format?.small?.url;
    }
    if (!imageUrl) {
        imageUrl = format?.thumbnail?.url;
    }
    return `${BACKEND_URL}${imageUrl}`;
};