import React, { useEffect } from "react";

import Banner from "../Banner";
import PastEvents from "../PastEvents"
import UpcomingWorkshops from "../UpcomingWorkshops";
import Contact from "../Pages/ContactUs"
import Footer from "../Footer";
import Header from "../Header";
import PastWorkshops from "../PastWorkshops";
import UpcomingEvents from "../UpcomingEvents";
import Presentations from "../Presentations";
import ParentsCouncil from "../ParentsCouncil/ParentsCouncil";
import UsefulContent from "../useful-content/UsefulContent";


const HomeOne = () => {

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <Presentations />
      {/* <PastWorkshops />
      <UpcomingWorkshops /> */}
      <UpcomingEvents />
      <ParentsCouncil />
      <PastEvents />
      <UsefulContent />
      <Contact />
      <Footer />
    </>
  );
};

export default HomeOne;
