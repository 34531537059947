/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getUsefulContent } from '../../constant/services/home';
import Lightbox from "../Lightbox/Lightbox";
import "../Lightbox/style.css";
import downloadIcon from "../../assets/images/svg/download-solid.svg";
import FileSaver from "file-saver";
import YoutubeEmbed from "../YoutubeEmbed";
import { getThumbnailURL } from "../../utils/utils";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ContentList = () => {
	const [events, setEvents] = useState([]);
	const [showImageModal, setShowImageModal] = useState(false);
	const [images, setImages] = useState([]);
	const [videoUrl, setVideoUrl] = useState('');
	const [showYoutubeModal, setShowYoutubeModal] = useState(false);

	const fetchUsefulContent = async () => {
		const res = await getUsefulContent();
		setEvents(res.data);
	}

	useEffect(() => {
		fetchUsefulContent();
	}, []);

	const showImageSlider = (event) => {
		const imagesArr = event?.attributes?.photo?.data?.reduce((acc, photo) => {
			const { url } = photo?.attributes;
			acc.push({
				url: `${BACKEND_URL}${url}`,
				title: event.attributes?.title,
				description: event.attributes?.description || '',
			});
			return acc;
		}, []);
		setImages(imagesArr);
		setShowImageModal(true);
	};

	const closeImageModal = () => {
		setImages([]);
		setShowImageModal(false);
	}

	const downloadFile = (document) => {
		const { name, url } = document?.data?.attributes
		FileSaver.saveAs(`${BACKEND_URL}${url}`, name);
	}

	const closeVideoModal = () => {
		setVideoUrl('');
		setShowYoutubeModal(false);
	}

	return (
		<div className={`nav-tab-wrapper tabs ${events.length > 0 ? 'section-padding' : ''}`}>
			<div className="container">
				{
					events.length === 0 &&
					<p style={{ margin: '50px 0px', textAlign: 'center' }}>No useful content to show.</p>
				}
				<div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
					{events.map((item, index) => (
						<div
							className=" bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3 hover:ring-2 hover:ring-primary
							hover:shadow-box8"
							key={index}
							// onClick={() => showImageSlider(item)}
							style={{ cursor: "pointer" }}
						>
							<div className="course-thumb h-[297px] rounded-t-[8px]  relative">
								<img
									src={getThumbnailURL(item, true)}
									alt=""
									className=" w-full h-full object-cover rounded-t-[8px]"
								/>
							</div>
							<div className="course-content p-8">
								<div className="flex flex-row justify-between">
									<h4 className=" text-xl mb-5 font-bold">
										<a
											className=" hover:text-primary transition duration-150 event-title"
										>
											{item?.attributes?.title}
										</a>
									</h4>
									<div style={{ display: 'flex' }}>
										{/* {item?.attributes?.instagram && <img
											src={instagramIcon}
											alt=""
											style={{ maxHeight: 24, minHeight: 24 }}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												window.open(item?.attributes?.instagram, '_blank')
											}}
										/>} */}
										{item?.attributes?.document?.data && <img
											src={downloadIcon}
											alt=""
											style={{ maxHeight: 24, minHeight: 24, marginLeft: 10 }}
											onClick={(e) => {
												e.stopPropagation();
												downloadFile(item?.attributes?.document)
											}}
										/>}
										{/* {item?.attributes?.youtube && <img
											src={youtubeIcon}
											alt=""
											className="object-cover"
											style={{ maxHeight: 24, minHeight: 24, marginLeft: 5 }}
											onClick={(e) => {
												e.stopPropagation();
												setVideoUrl(item?.attributes?.youtube);
												setShowYoutubeModal(true);
											}}
										/>} */}
									</div>
								</div>
								{/* <ul className=" list space-y-3 mb-6">
									<li className=" flex space-x-2">
										<span className="text-lg  text-secondary">
											<iconify-icon icon="heroicons:calendar-days"></iconify-icon>
										</span>
										<span className="text">{new Date(item?.attributes?.date).toLocaleString('en-CA', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</span>

									</li>
								</ul> */}
							</div>
						</div>
					))}
				</div>
			</div>
			{
				(showImageModal && images?.length === 1) &&
				<Lightbox
					image={images[0].url}
					title={images[0].title}
					description={images[0].description}
					onClose={closeImageModal}
				/>
			}
			{
				(showImageModal && images?.length > 1) && <Lightbox images={images} onClose={closeImageModal} />
			}
			{showYoutubeModal && <YoutubeEmbed modalOpen={showYoutubeModal} setModalOpen={closeVideoModal} videoUrl={videoUrl} />}
		</div>
	);
};

export default ContentList;
