import React from "react";
import EventsList from "./EventsList";

const ParentsCouncil = () => {
  return (
    <div id="parents-council">
      <div className="text-center">
        <div className="column-title ">
          Parents <span className="shape-bg">Council Updates</span>
        </div>
      </div>
      <EventsList />
    </div>
  );
};

export default ParentsCouncil;
