/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeExternalLinks from 'rehype-external-links';
import { calender2 } from "../constant/images";
import { getUpcomingEvents } from '../constant/services/home';
import PageBanner from "./PageBanner";
import Modal from 'react-modal';
import pdfIcon from "../assets/images/svg/file-pdf-solid.svg";
import youtubeIcon from "../assets/images/social/youtube.svg";
import FileSaver from "file-saver";
import YoutubeEmbed from "./YoutubeEmbed";
import { getThumbnailURL } from "../utils/utils";
import StaffAppreciationLuncheon from "./StaffAppreciationLuncheon";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 16,
		maxWidth: '80%',
		minWidth: '70%',
		maxHeight: '70%'
	},
};

const UpcomingEvents = () => {
	const [events, setEvents] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [videoUrl, setVideoUrl] = useState('');
	const [showYoutubeModal, setShowYoutubeModal] = useState(false);

	const fetchUpcomingEvents = async () => {
		const res = await getUpcomingEvents();
		setEvents(res?.data || []);
	};

	useEffect(() => {
		fetchUpcomingEvents();
	}, []);

	useEffect(() => {
		if (modalOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [modalOpen]);

	const downloadFile = (document) => {
		const { name, url } = document?.data?.attributes
		FileSaver.saveAs(`${BACKEND_URL}${url}`, name);
	}

	const closeVideoModal = () => {
		setVideoUrl('');
		setShowYoutubeModal(false);
	}

	const renderCardDetails = (item, showDescription = false) => {
		let showForm = false;
		if (showDescription && item?.attributes?.title === 'Staff Appreciation Lunch: Click here for more information!') {
			showForm = true;
		}
		return (<div
			className=" bg-white shadow-box7 rounded-[8px] group transition duration-150 ring-0 hover:ring-2 hover:ring-primary
    hover:shadow-box8 sm:flex p-4 sm:space-x-6 space-y-6 sm:space-y-0"
			key={1}
			style={{ cursor: "pointer" }}
			onClick={() => {
				setSelectedItem(item);
				setModalOpen(true);
			}}
		>
			<div className="flex-none">
				<div className="sm:w-[200px] h-[182px]  rounded  relative">
					<img
						src={getThumbnailURL(item, true)}
						alt=""
						className=" w-full h-full object-cover rounded"
					/>
				</div>
			</div>
			<div className="course-content flex-1">
				<h4 className=" lg:text-2xl lg:leading-[36px] text-1xl mb-4 font-bold">
					{item?.attributes?.title}
				</h4>
				<div className="flex space-x-6">
					<div className=" flex items-center space-x-2" href="#">
						<img src={calender2} alt="" />
						<span className="text">{new Date(item?.attributes?.date).toLocaleString('en-CA', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</span>
					</div>
					<div className="flex">
						{item?.attributes?.document?.data && <img
							src={pdfIcon}
							alt=""
							style={{ maxHeight: 24, minHeight: 24 }}
							onClick={(e) => {
								e.stopPropagation();
								downloadFile(item?.attributes?.document)
							}}
						/>}
						{item?.attributes?.youtube && <img
							src={youtubeIcon}
							alt=""
							className="object-cover"
							style={{ maxHeight: 24, minHeight: 24, marginLeft: 5 }}
							onClick={(e) => {
								e.stopPropagation();
								setVideoUrl(item?.attributes?.youtube);
								setShowYoutubeModal(true);
							}}
						/>}
					</div>
				</div>
				{showDescription && <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>{item?.attributes?.description}</Markdown>}
				{showForm && <StaffAppreciationLuncheon />}
			</div>
		</div>)
	}

	return (
		<div className=" section-padding" id="events-upcoming" style={{ paddingTop: 0 }}>
			<PageBanner title={"Upcoming Events"} pageTitle="Events" />
			<div className="container">
				<div className="grid  xl:grid-cols-2 grid-cols-1 gap-[30px] lg:pt-10  pt-5">
					{events.map((item, index) => renderCardDetails(item))}
				</div>
			</div>
			<Modal
				isOpen={modalOpen}
				onRequestClose={() => setModalOpen(false)}
				contentLabel="Example Modal"
				style={customStyles}
				preventScroll
			>
				{renderCardDetails(selectedItem, true)}
			</Modal>
			{showYoutubeModal && <YoutubeEmbed modalOpen={showYoutubeModal} setModalOpen={closeVideoModal} videoUrl={videoUrl} />}
		</div>
	);
};

export default UpcomingEvents;
