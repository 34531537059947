/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { getPresentations } from '../constant/services/home';
import { Autoplay, EffectCreative, Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from 'react-modal';
import noPreview from "../assets/images/others/image.jpg";
import { calender2 } from "../constant/images";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Markdown from "react-markdown";
import FileSaver from "file-saver";
import pdfIcon from "../assets/images/svg/file-pdf-solid.svg";
import youtubeIcon from "../assets/images/social/youtube.svg";
import YoutubeEmbed from "./YoutubeEmbed";
import { getThumbnailURL } from "../utils/utils";

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 16,
		maxWidth: '80%',
		minWidth: '50%',
		maxHeight: '50%',
		zIndex: 999999,
	},
};

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Presentations = () => {

	const [presentations, setPresentations] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [videoUrl, setVideoUrl] = useState('');
	const [showYoutubeModal, setShowYoutubeModal] = useState(false);

	const fetchEvents = async () => {
		const res = await getPresentations();
		setPresentations(res.data);
	}

	useEffect(() => {
		if (modalOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [modalOpen]);

	useEffect(() => {
		fetchEvents();
	}, []);

	const downloadFile = (document) => {
		const { name, url } = document?.data?.attributes
		FileSaver.saveAs(`${BACKEND_URL}${url}`, name);
	}

	const renderCardDetails = (item, showDescription = false) => {
		return (<div
			className=" bg-white shadow-box7 rounded-[8px] group transition duration-150 ring-0 hover:ring-2 hover:ring-primary
		hover:shadow-box8 sm:flex p-4 sm:space-x-6 space-y-6 sm:space-y-0"
			key={1}
			style={{ cursor: "pointer" }}
			onClick={() => {
				setSelectedItem(item);
				setModalOpen(true);
			}}
		>
			<div className="flex-none">
				<div className="sm:w-[200px] h-[182px]  rounded  relative">
					<img
						src={getThumbnailURL(item, true)}
						alt=""
						className=" w-full h-full object-cover rounded"
					/>
				</div>
			</div>
			<div className="course-content flex-1">
				<h4 className=" lg:text-2xl lg:leading-[36px] text-1xl mb-4 font-bold">
					{item?.attributes?.title}
				</h4>
				<div className="flex   space-x-6">
					<div className=" flex items-center space-x-2" href="#">
						<img src={calender2} alt="" />
						<span>{new Date(item?.attributes?.date).toLocaleString('en-CA', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })}</span>
					</div>
				</div>
				<br />
				{showDescription && <Markdown >{item?.attributes?.description}</Markdown>}
			</div>
		</div>)
	}

	const closeVideoModal = () => {
		setVideoUrl('');
		setShowYoutubeModal(false);
	}

	return (
		<>
			<div className="section-padding" id="tuesdays-at-ridge" style={{ paddingTop: 0 }}>
				<div className="container">
					<div className="text-center">
						{/* <div className="mini-title">Start Learning From Today</div> */}
						<div className="column-title " style={{ paddingTop: '1.5rem' }}>
							Tuesdays @ The Ridge<span className="shape-bg"></span>
						</div>
						<p style={{ textTransform: 'none', textAlign: 'justify' }}>Join other Iroquois Ridge parents for talks on the first Tuesday of every month at 10 am or 7 pm. These presentations, followed by open discussion, are educational and informative, and often cover things we haven't learned about navigating the teenage years. The talks cover topics like resiliency, communication, choosing courses, emotional health and well being. Our accomplished speakers include life coaches, counsellors, physicians, teachers, police officers, and other community leaders who can help us better understand our teens. Mark your calendars!</p>
					</div>
					<Swiper
						loop
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						slidesPerView={1}
						breakpoints={{
							768: {
								slidesPerView: 2,
								spaceBetween: 32,
							},
							1024: {
								slidesPerView: 4,
								spaceBetween: 32,
							},
						}}
						navigation
						creativeEffect={{
							prev: {
								shadow: true,
								translate: [0, 0, -400],
								opacity: [0],
							},
							next: {
								translate: ["100%", 0, 0],
							},
						}}
						centeredSlides
						modules={[Navigation, EffectCreative, Autoplay, A11y]}
						className="mt-4"
					>
						{presentations.map((item, index) => {
							const thumbnail = getThumbnailURL(item, true);
							return (
								<SwiperSlide className="rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#E2EDE8] text-center px-6
							py-[35px] hover:-translate-y-2 slide-presentation" key={index}
									onClick={() => {
										setSelectedItem(item);
										setModalOpen(true);
									}}
								>
									<>
										<div className="bg-white relative mx-auto flex flex-col mb-8 group-hover:bg-[#E2EDE8]">
											{thumbnail ?
												<img
													src={thumbnail}
													alt=""
													className="object-cover"
													style={{ maxHeight: 120, minHeight: 120 }}
												/> :
												<img
													src={noPreview}
													alt=""
													className="object-cover"
													style={{ maxHeight: 120, minHeight: 120 }}
												/>}
										</div>
										<div className="course-content">
											<h5 className="text-2xl  mb-2 font-bold long-title" title={item?.attributes?.title}>{item?.attributes?.title}</h5>
											<div className="download-file-container">
												<span className="text">{new Date(item?.attributes?.date).toLocaleString('en-CA', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })}</span>
												<div className="flex">
													{item?.attributes?.document?.data && <img
														src={pdfIcon}
														alt=""
														className="object-cover"
														style={{ maxHeight: 24, minHeight: 24 }}
														onClick={(e) => {
															e.stopPropagation();
															downloadFile(item?.attributes?.document)
														}}
													/>}
													{item?.attributes?.youtube && <img
														src={youtubeIcon}
														alt=""
														className="object-cover"
														style={{ maxHeight: 24, minHeight: 24, marginLeft: 5 }}
														onClick={(e) => {
															e.stopPropagation();
															setVideoUrl(item?.attributes?.youtube);
															setShowYoutubeModal(true);
														}}
													/>}
												</div>
											</div>
										</div>
									</>
								</SwiperSlide>
							)
						})}

					</Swiper>
				</div>

			</div>
			<Modal
				isOpen={modalOpen}
				onRequestClose={() => setModalOpen(false)}
				style={customStyles}
				preventScroll
			>
				{renderCardDetails(selectedItem, true)}
			</Modal>
			{showYoutubeModal && <YoutubeEmbed modalOpen={showYoutubeModal} setModalOpen={closeVideoModal} videoUrl={videoUrl} />}
		</>
	)
};

export default Presentations;
