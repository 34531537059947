/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ihrslogo from "../assets/images/logo/irhs-logo.svg";
import MobileMenu from "./MobileMenu";

const Header = () => {
	const [activeMobileMenu, setActiveMobileMenu] = useState(false);

	const scrollNav = useRef(null);
	
	useEffect(() => {
		// scrolling nav
		window.addEventListener("scroll", () => {
			let windowScroll = window.scrollY > 100;
			scrollNav?.current?.classList?.toggle("rt-sticky-active", windowScroll);
			scrollNav?.current?.classList?.toggle("sticky", windowScroll);
		});
	}, []);

	return (
		<>
			<header
				className="site-header home-one-header top-0 w-full z-[999] rt-sticky "
				ref={scrollNav}
			>
				<div className="main-header py-6">
					<div className="">
						<div className=" flex items-center justify-between">
							<Link
								to={"/"}
								className="brand-logo flex-none lg:mr-10 lg:ml-10 md:w-auto "
								style={{ maxWidth: 200, width: 200 }}
							>
								<img src={ihrslogo} alt="logo" />
							</Link>
							<div className="flex items-center flex-1">
								<div className="flex-1 main-menu relative mr-[74px]">
									<ul className="menu-active-classNames">
										<li>
											<a href="#tuesdays-at-ridge">Tuesdays @ The Ridge</a>
										</li>
										<li className='menu-item-has-children'>
											<a href="">Events</a>
											<ul className="sub-menu">
												<li>
													<a href="#events-past">Past Events</a>
												</li>
												<li>
													<a href="#events-upcoming">Upcoming Events</a>
												</li>
											</ul>
										</li>
										<li>
											<a href="https://irs.hdsb.ca/" target="_blank" rel="noreferrer">IRHS Site</a>
										</li>
										<li>
											<a href="#footer">Volunteer Sign Up</a>
										</li>
										<li>
											<a href="#useful-content">Useful Content</a>
										</li>
										<li>
											<a href="/tnc">Terms of Use</a>
										</li>
									</ul>
								</div>
								<div className="flex-none flex space-x-[18px]">
									<div className=" block   lg:hidden">
										<button
											type="button"
											className=" text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center
                                        menu-click"
											onClick={() => setActiveMobileMenu(!activeMobileMenu)}
										>
											<iconify-icon
												icon="cil:hamburger-menu"
												rotate="180deg"
											></iconify-icon>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			{activeMobileMenu && (
				<MobileMenu
					activeMenu={activeMobileMenu}
					setActiveMenu={setActiveMobileMenu}
				/>
			)}

		</>
	);
};

export default Header;
