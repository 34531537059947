import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Terms of Use"} pageTitle="Terms of Use" num={1} />
      <div className="about-area terms-of-use" style={{paddingTop: 50, paddingBottom: 50}}>
        <div className="container">
          <h2>Introduction to Use</h2>
          <p>By accessing and using this website, you agree to each of the terms and conditions set forth herein in this Terms of Use policy ("Policy") of IRHS Parent Engagement Program (PEP).</p>
          <p>&nbsp;</p>
          <h2>Links</h2>
          <p>IRHS PEP may provide a number of links to third party websites including those that are not maintained by IRHS PEP. IRHS PEP assumes no responsibility for the content or practices of websites you are able to access through our website, or any opinions expressed on such websites. IRHS PEP does not provide any warranties whatsoever with respect to other websites, including, but not limited to, with respect to accuracy, completeness or timeliness.</p>
          <p>&nbsp;</p>
          <h2>User Conduct</h2>
          <p>You agree to use the website for lawful purposes only. You agree not to take any action that might compromise the security of the website, render the website inaccessible to others or otherwise cause damage to the website or the content. You agree not to add to, subtract from, or otherwise modify the content, or to attempt to access any content that is not intended for you. You agree not to use the website in any manner that might interfere with the rights of third parties.</p>
          <p>&nbsp;</p>
          <h2>Intellectual Property</h2>
          <p>All materials published or otherwise accessible through this website are protected by copyright and are owned or controlled by IRHS PEP or used by IRHS PEP with permission. You may not use content without permission from the owner. You agree to abide by all additional copyright notices and restrictions contained on the website.</p>
          <p>&nbsp;</p>
          <h2>Licence</h2>
          <p>IRHS PEP grants to you a limited licence to display on your computer, print, download and use the content made available to you on this website. Notwithstanding any of these terms and conditions, IRHS PEP reserves the right, without notice and in its sole discretion, to terminate your license to use this website, and to block or prevent future access to and use of the website if you violate any of the terms of this Policy. Upon termination, the terms of this Policy shall continue to apply.</p>
          <p>Disclaimer</p>
          <p>&nbsp;</p>
          <h2>No Endorsement</h2>
          <p>Inclusion of an organization and its volunteer opportunities on the volunteerdufferin.ca website does not indicate endorsement, express or implied, or an evaluation of service quality by IRHS PEP or its partners, nor does omission indicate an evaluation of quality or lack of endorsement.</p>
          <p>&nbsp;</p>
          <h2>No Warranties</h2>
          <p>The information contained on the volunteerdufferin.ca website is provided for information purposes only for individuals, families, groups and businesses who are seeking to make a volunteer contribution within their community. While considerable efforts are made to ensure that correct information is included regarding each volunteer opportunity, volunteer and event postings may contain errors. All information is provided on an "as is" basis without any warranties or representations of any kind.</p>
          <p>&nbsp;</p>
          <h2>No Liability</h2>
          <p>IRHS PEP does not accept any liability for your use of the website. Use of the website is at your own risk. IRHS PEP is not responsible for any damages whatsoever, including direct, indirect, special, incidental or consequential damages, however caused, arising out of any connection with or use of the volunteer opportunity database held at volunteerdufferin.ca, or in reliance on the information made available on the volunteerdufferin.ca website. It is the sole responsibility of the member organization to screen, train, support and ensure the safety of individuals who offer volunteer service, whether or not they became aware of the volunteer opportunity through the volunteerdufferin.ca website. Likewise, it is the sole responsibility of individual volunteers to review the organizations and make an informed decision as to whether or not they wish to volunteer.</p>
          <p>&nbsp;</p>
          <h2>Release</h2>
          You agree to release IRHS PEP from and against any and all liability, claims, damages, actions, and costs, arising out of or in connection with your use, review or reproduction of the IRHS PEP website.
          <p>&nbsp;</p>
          <h2>Governing Law</h2>
          This Policy shall be construed and enforced in accordance with the laws of the Province of Ontario, Canada.
          <p>&nbsp;</p>
          <h2>Entire Agreement</h2>
          These terms and conditions represent the entire agreement between you and IRHS PEP regarding the Policy and supersede and replace any prior agreements between you and IRHS PEP regarding the website.
          <p>&nbsp;</p>
          <h2>Modifications</h2>
          IRHS PEP reserves the right to modify this Policy at any time without giving you prior notice. Your use of the website following any such modification constitutes your agreement to follow and be bound by the Agreement as modified.
          <p>&nbsp;</p>
        <Link to={"/"} className="btn btn-primary">
          Back To Home
        </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
