import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 16,
        minHeight: '40%',
        zIndex: 999999,
    },
};

const getVideoId = (url) => {
    const youtubeIdRegex =
        // eslint-disable-next-line no-useless-escape
        /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*?(?:\/|v\/|u\/|embed\/|shorts\/|watch\?v=|(?<username>user\/))(?<id>[\w\-]{11})(?:\?|&|$)/;
    const match = url.match(youtubeIdRegex);
    return match?.groups?.id || false;
};

function YoutubeEmbed({ modalOpen, setModalOpen, videoUrl = ''}) {
    const embedId = getVideoId(videoUrl);

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            preventScroll
            style={customStyles}
        >
            <iframe
                src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                width="560" height="315"
            />
        </Modal>
    )
}

export default YoutubeEmbed;