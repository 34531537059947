/* eslint-disable import/no-anonymous-default-export */
import request from '../../utils/request';

const getPresentations = () => request.get('presentations?populate=*');
const getPastWorkshops = () => request.get('past-workshops?populate=*') ;
const getUpcomingWorkshops = () => request.get('upcoming-workshops?populate=*');
const getPastEvents = () => request.get('past-events?populate=*');
const getUpcomingEvents = () => request.get('upcoming-events?populate=*');
const getContacts = () => request.get('contact?populate=*');
const volunteerSignUp = (payload) => request.post('volunteer-signups', payload);
const postNewsLetterEntry = (payload) => request.post('newsletters', payload);
const getParentsCouncilUpdates = () => request.get('parents-council-updates?populate=*');
const postStaffAppreciationLunch = (payload) => request.post('staff-appreciation-luncheons', payload);
const getUsefulContent = () => request.get('useful-contents?populate=*');

export {
    getPastEvents,
    getUpcomingWorkshops,
    getPastWorkshops,
    getPresentations,
    getUpcomingEvents,
    getContacts,
    volunteerSignUp,
    postNewsLetterEntry,
    getParentsCouncilUpdates,
    postStaffAppreciationLunch,
    getUsefulContent
};