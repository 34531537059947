import React from "react";
import EventsList from "./EventsList";

const PastEvents = () => {
  return (
    <div id="events-past">
      {/* <Header /> */}
      <div className="text-center">
        <div className="column-title ">
          Past <span className="shape-bg">Events</span>
        </div>
      </div>
      <EventsList />
      {/* <Footer /> */}
    </div>
  );
};

export default PastEvents;
