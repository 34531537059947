import React from "react";
import { mail } from "../../constant/images";
import PageBanner from "../PageBanner";

const ContactUs = () => {
  return (
    <>
      <PageBanner title={"Get In Touch Today"} pageName="Contact" />
      <div className="nav-tab-wrapper tabs  section-padding" style={{ paddingTop: 40, paddingBottom: 40 }}>
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <ul className=" list-item space-y-6 pt-2">
                <li className="flex">
                  <div className="flex-none mr-6">
                    <div className="">
                      <img src={mail} alt="" className="" />
                    </div>
                  </div>
                  <div className="flex-1">
                    <h4 className="column-title" style={{ marginBottom: 0, fontSize: 36 }}>Email:</h4>
                    <div className="column-title" style={{ textTransform: 'none', marginBottom: 0, fontSize: 36  }}>volunteer@irhsparents.com</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
